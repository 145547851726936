import { ref } from '@vue/composition-api'

import store from '@/store'
import {
  INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE, BUTTON_ACTION_PERMISSIONS, TAX_TYPES, TAX_TYPES_OBJECT, GOODS_SERVICE_TYPES, PAYMENT_METHOD_DEFAULT, ADJUSTMENT_TYPE_OPTIONS,
  ADJUSTMENT_TYPE, DEFAULT_VAT_RATE, TAX_RATE_TYPES, INVOICE_STATUS_VALUES, FLIGHT_TYPE,
  CREATED_TYPE_STATUS,
} from '@/constants/invoice'
import { apiInvoice } from '@/api'

import useToast from '@useToast'

export default function useInvoiceHandle() {
  const { toastError, toastSuccess } = useToast()
  const loading = ref(false)
  const getInvoiceById = (id, isInvoiceRequest = false) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/getInvoiceById', { id, isInvoiceRequest })
      .then(res => {
        resolve(isInvoiceRequest ? res?.data : res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const getTransmissionHistory = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/getTransmissionHistory', id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const getInvoicePdfById = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/getInvoicePdfById', id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })
  const loadingGetTemplateId = ref(false)
  const getTemplateId = id => new Promise((resolve, reject) => {
    loadingGetTemplateId.value = true
    store
      .dispatch('app-invoice/getTemplateId', id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingGetTemplateId.value = false
      })
  })

  const signInvoice = (id, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/signInvoice', { id, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.signSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.signError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  // BỎ dùng api lô thay thế
  // const signAndReleaseInvoice = (id, payload) => new Promise((resolve, reject) => {
  //   loading.value = true
  //   store
  //     .dispatch('app-invoice/signAndReleaseInvoice', { id, payload })
  //     .then(res => {
  //       resolve(res)
  //       toastSuccess({
  //         title: 'messagesList.success',
  //         content: 'invoice.msg.signAndReleaseSuccess',
  //       })
  //     })
  //     .catch(e => {
  //       toastError({
  //         title: 'messagesList.error',
  //         content: 'invoice.msg.signAndReleaseError',
  //       })
  //       reject(e)
  //     })
  //     .finally(() => {
  //       loading.value = false
  //     })
  // })

  const getCountProcessingReleaseBatch = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/getCountProcessingReleaseBatch', payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const releaseBatch = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/releaseBatch', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.releaseBatchSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.releaseBatchError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const getXmlInvoicesInBatch = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/getXmlInvoicesInBatch', payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.getXmlInvoicesInBatchError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })
  const loadingGetCompanyCertificates = ref(false)
  const getCompanyCertificates = (companyId, params) => new Promise((resolve, reject) => {
    loadingGetCompanyCertificates.value = true
    store
      .dispatch('app-invoice/getCompanyCertificates', { companyId, params })
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingGetCompanyCertificates.value = false
      })
  })

  const releaseInvoice = invoiceId => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/releaseInvoice', invoiceId)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.releaseInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.releaseInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const sendMailInvoice = invoiceId => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/sendMailInvoice', invoiceId)
      .then(res => {
        resolve(res.data)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.sendEmailSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.sendEmailError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const updateInvoice = (invoiceId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/updateInvoice', { id: invoiceId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.updateInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.updateInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const cancelInvoice = (invoiceId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/cancelInvoice', { id: invoiceId, payload })
      .then(res => {
        resolve(res.data)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.cancelInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.cancelInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const cancelBatchInvoice = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/cancelBatchInvoice', payload)
      .then(res => {
        resolve(res.data)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.cancelInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.cancelInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const loadingTags = ref(false)
  const getTags = (text = '') => new Promise((resolve, reject) => {
    loadingTags.value = true
    store
      .dispatch('app-invoice/getTags', text)
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loadingTags.value = false
      })
  })

  const replaceInvoice = (invoiceId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/replaceInvoice', { id: invoiceId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.replaceInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.replaceInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const adjustInvoice = (invoiceId, payload) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/adjustInvoice', { id: invoiceId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.adjustInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.adjustInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  // const duplicateInvoice = invoiceId => new Promise((resolve, reject) => {
  //   vm.$bvModal.msgBoxConfirm(vm.$t('invoice.msg.confirmDuplicate'), {
  //     title: vm.$t('invoice.btn.duplicateInvoice'),
  //     size: 'sm',
  //     okVariant: 'primary',
  //     okTitle: vm.$t('modal.yes'),
  //     cancelTitle: vm.$t('modal.no'),
  //     cancelVariant: 'outline-secondary',
  //     hideHeaderClose: true,
  //     centered: true,
  //   })
  //     .then(isConfirm => {
  //       if (!isConfirm) return
  //       vm.$bvModal.show('modal-api-loading')
  //       store
  //         .dispatch('app-invoice/duplicateInvoice', { id: invoiceId })
  //         .then(res => {
  //           resolve(res)
  //           toastSuccess({
  //             title: 'messagesList.success',
  //             content: 'invoice.msg.duplicateInvoiceSuccess',
  //           })
  //         })
  //         .catch(e => {
  //           toastError({
  //             title: 'messagesList.error',
  //             content: 'invoice.msg.duplicateInvoiceError',
  //           })
  //           reject(e)
  //         })
  //         .finally(() => {
  //           vm.$bvModal.hide('modal-api-loading')
  //         })
  //     })
  // })

  const duplicateInvoice = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-invoice/createInvoice', payload)
      .then(res => {
        resolve(res)
        toastSuccess('invoice.msg.duplicateInvoiceSuccess')
      })
      .catch(e => {
        reject(e)
        toastError('invoice.msg.duplicateInvoiceError')
      })
      .finally(() => {
        loading.value = false
      })
  })

  const approveInvoiceRequest = (id, isApprove = false) => new Promise((resolve, reject) => {
    loading.value = true
    const payload = {
      invoiceId: id,
      status: isApprove ? CREATED_TYPE_STATUS.ACCEPTED : CREATED_TYPE_STATUS.REJECTED,
    }
    store
      .dispatch('app-invoice/approveInvoiceRequest', payload)
      .then(res => {
        resolve(res)
        toastSuccess(isApprove ? 'invoice.msg.approveInvoiceRequestSuccess' : 'invoice.msg.rejectInvoiceRequestSuccess')
      })
      .catch(e => {
        reject(e)
        toastError(isApprove ? 'invoice.msg.approveInvoiceRequestError' : 'invoice.msg.rejectInvoiceRequestError')
      })
      .finally(() => {
        loading.value = false
      })
  })
  return {
    getInvoiceById,
    getInvoicePdfById,
    loading,
    loadingGetCompanyCertificates,
    loadingGetTemplateId,
    INVOICE_STORE,
    getTransmissionHistory,
    getTemplateId,
    SALE_REPORT_STORE: 'app-sale-report',
    signInvoice,
    // signAndReleaseInvoice,
    releaseBatch,
    getCountProcessingReleaseBatch,
    getCompanyCertificates,
    releaseInvoice,
    BUTTON_ACTION_PERMISSIONS,
    sendMailInvoice,
    loadingTags,
    getTags,
    TAX_TYPES,
    TAX_TYPES_OBJECT,
    updateInvoice,
    cancelInvoice,
    GOODS_SERVICE_TYPES,
    PAYMENT_METHOD_DEFAULT,
    adjustInvoice,
    replaceInvoice,
    ADJUSTMENT_TYPE_OPTIONS,
    ADJUSTMENT_TYPE,
    DEFAULT_VAT_RATE,
    duplicateInvoice,
    TAX_RATE_TYPES,
    INVOICE_STATUS_VALUES,
    FLIGHT_TYPE,
    cancelBatchInvoice,
    getXmlInvoicesInBatch,
    approveInvoiceRequest,
  }
}

export function resolveInvoiceStatus(status) {
  const statusMapColor = {
    NEW: 'light-success',
    SIGNED: 'secondary',
    ADJUSTMENT: 'light-info',
    ADJUSTED: 'light-warning',
    REPLACEMENT: 'light-pink',
    REPLACED: 'light-danger',
    CANCELLED: 'danger',
    ERROR: 'outline-danger',
  }
  return statusMapColor[status]
}

export function resolveInvoiceAdditionalStatus(status) {
  const statusMapColor = {
    UNPAID: 'secondary',
    PAID: 'success',
  }
  return statusMapColor[status]
}

export function resolveReleaseStatus(status) {
  const statusMapColor = {
    NEW: 'light-warning',
    SENT: 'light-info',
    CQT_ISSUED_CODE: 'light-success',
    CQT_REJECT_ISSUE_CODE: 'light-danger',
  }
  return statusMapColor[status]
}

export function resolveSendMailStatus(status) {
  const statusMap = {
    WAITING: { color: 'danger', icon: 'envelopeBan' },
    SENT: { color: 'success', icon: 'envelopeDownload' },
  }
  return statusMap[status]
}

export function resolveSignStatus(status) {
  const statusMap = {
    UNSIGNED: { color: 'danger' },
    SIGNED: { color: 'success' },
  }
  return statusMap[status]
}

export function numberToFixed(value, numberOfDecimal = 2) {
  return Number(Number(value).toFixed(numberOfDecimal))
}

// let portalToken
export async function getPortalToken() {
  const companyId = store.getters['userStore/getAgencyCompany']?.id
  if (companyId) {
    store.dispatch('app/setLoading', true)
    const res = await apiInvoice.generateTokenForRedirectPortal(companyId)
    store.dispatch('app/setLoading', false)
    // portalToken = res
    return res
  }
  return null
}
