<template lang="">
  <!-- SECTION Thông tin xuất hoá đơn -->
  <BCard
    id="invoice-create-info-card"
    body-class="p-50"
  >
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="usersOutline"
        size="25"
        class="p-1 icon-card"
        @dblclick.native.prevent="$emit('quick-input')"
      />
      <span class="font-medium-3 m-0 fw-700">
        {{ $tc('invoice.create.info.title', isInvoiceRequest ? 2 : 1) }}
      </span>
    </div>
    <!-- SECTION row 1 -->
    <b-row class="px-75">
      <!-- ANCHOR thông tin bên bán: là chọn company của `agency đăng nhập` hoặc `agency cha của nó` company (tạm thời bỏ thằng cha của nó) => chọn `templateId` -->
      <b-col
        cols="12"
        class="px-25"
        md="4"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.create.info.sellerInfo')"
          rules="required"
          vid="sellerInfo"
        >
          <b-form-group
            label-for="sellerInfo"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.create.info.sellerInfo') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="sellerInfo"
              :value="invoiceData.company"
              :name="$t('invoice.create.info.sellerInfo')"
              class="w-100"
              style="font-size: 1rem"
              :options="companyOptions"
              label="name"
              :disabled="!isEditing"
              :clearable="false"
              :placeholder="$t('placeholderSelect')"
              @input="(val) => $emit('update:invoiceData', { ...invoiceData, company: val })"
            >
              <template #option="data">
                {{ data.name }}
              </template>
              <template #selected-option="data">
                {{ data.name }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="sellerInfo"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ký hiệu -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.symbol')">
          <b-form-input
            :value="invoiceData.symbol"
            :placeholder="$t('invoice.create.info.phSymbol')"
            :formatter="onlyUpperCaseFormatter"
            disabled
            @input="
              (val) =>
                $emit('update:invoiceData', { ...invoiceData, symbol: val })
            "
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mẫu số hoá đơn -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.series')">
          <b-form-input
            :value="invoiceData.templateNumber"
            :formatter="onlyUpperCaseFormatter"
            :placeholder="$t('invoice.create.info.phSeries')"
            disabled
            @input="
              (val) =>
                $emit('update:invoiceData', {
                  ...invoiceData,
                  templateNumber: val,
                })
            "
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Số hoá đơn -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.invNumber')">
          <b-form-input
            :value="invoiceData.invNumber"
            disabled
            :placeholder="`${
              (
                invoiceData.company?.generateInvoiceNumberOnCreation
                  ? `${$t('agency.company.field.generateInvoiceNumberOnCreation')} ${$t('agency.company.field.onCreation')}`
                  : `${$t('agency.company.field.generateInvoiceNumberOnCreation')} ${$t('agency.company.field.onRelease')}`
              )
              ?? $t('invoice.create.info.invNumber')
            }`"
            :formatter="onlyUpperCaseFormatter"
            @input="(val) => $emit('update:invoiceData', { ...invoiceData, invNumber: val })"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mã CQT: chỉ có khi gửi CQT duyệt, `released invoice code` (check api)-->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.taxAuthorityCode')">
          <b-form-input
            :value="invoiceData?.releasedInvCode"
            disabled
            :placeholder="$t('invoice.create.info.taxAuthorityCode')"
            :formatter="onlyUpperCaseFormatter"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- !SECTION -->

    <!-- SECTION row 3 -->
    <b-row class="px-75">
      <!-- ANCHOR Loại hoá đơn -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.create.info.invoiceType')"
          rules="required"
          vid="invoiceType"
        >
          <b-form-group
            label-for="invoiceType"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.create.info.invoiceType') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="invoiceType"
              :value="invoiceData?.invoiceType"
              :name="$t('invoice.create.info.invoiceType')"
              :options="[]"
              disabled
              style="font-size: 1rem"
              :clearable="false"
              :placeholder="$t('invoice.create.info.phInvoiceType')"
              @input="(val) => $emit('update:invoiceData', { ...invoiceData, invoiceType: val })"
            >
              <template #option="data">
                {{ data.label }}
              </template>
              <template #selected-option="data">
                {{ data.label }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="invoiceType"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Mẫu hoá đơn: danh sách `templateId` theo company (thông tin bên bán) đã chọn -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.create.info.invoiceTemplate')"
          rules="required"
          vid="invoiceTemplate"
        >
          <b-form-group
            label-for="invoiceTemplate"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.create.info.invoiceTemplate') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="invoiceTemplate"
              :value="invoiceData.template"
              :name="$t('invoice.create.info.invoiceTemplate')"
              :options="invoiceTemplateOptions"
              label="name"
              :disabled="!isEditing"
              :clearable="false"
              style="font-size: 1rem"
              :placeholder="$t('invoice.create.info.phInvoiceTemplate')"
              @input="(val) => handleSelectInvoiceTemplate(val)"
            >
              <template #option="data">
                {{ data.name }}
              </template>
              <template #selected-option="data">
                {{ data.name }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="invoiceTemplate"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Hình thức thanh toán -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.create.info.paymentMethod')"
          rules=""
          vid="paymentMethod"
        >
          <b-form-group
            label-for="paymentMethod"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.create.info.paymentMethod') }}
              <!-- <span class="text-danger">(*)</span> -->
            </template>
            <v-select
              id="paymentMethod"
              :value="invoiceData.paymentMethod"
              :options="PAYMENT_METHOD_LIST"
              :clearable="false"
              :disabled="!isEditing"
              style="font-size: 1rem"
              :placeholder="$t('invoice.create.info.phPaymentMethod')"
              :reduce="(val) => val.value"
              @input="(val) => $emit('update:invoiceData', { ...invoiceData, paymentMethod: val })"
            >
              <template #option="{ label }">
                {{ label }}
              </template>
              <template #selected-option="{ label }">
                {{ label }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="paymentMethod"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR ngày lập -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.create.info.createdAt')"
        >
          <flat-pickr
            :value="invoiceData.createdAt"
            class="form-control"
            :placeholder="`${$t('invoice.create.info.createdAt')}`"
            :config="{
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              locale: $i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
            disabled
            @input="(val) => $emit('update:invoiceData', { ...invoiceData, createdAt: val })"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mã tra cứu -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.lookUpCode')">
          <b-form-input
            :value="invoiceData?.referenceCode"
            disabled
            :placeholder="$t('invoice.create.info.lookUpCode')"
            :formatter="onlyUpperCaseFormatter"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Mã giao dịch -->
      <b-col
        cols="12"
        class="px-25"
        md="2"
      >
        <b-form-group :label="$t('invoice.create.info.transactionCode')">
          <b-form-input
            :value="null"
            disabled
            :placeholder="$t('invoice.create.info.transactionCode')"
            :formatter="onlyUpperCaseFormatter"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- !SECTION -->

    <!-- SECTION row 4 -->
    <b-row class="px-75">
      <!-- ANCHOR Trạng thái hoá đơn: enums theo tài liệu, ko cho sửa -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group :label="$t('invoice.create.info.invoiceStatus')">
          <v-select
            :value="invoiceData?.status ?? invoiceData?.invoiceStatus"
            class="w-100 "
            style="font-size: 1rem"
            :options="INVOICE_STATUS"
            label="label"
            disabled
            :clearable="false"
            :placeholder="$t('invoice.create.info.invoiceStatus')"
            :reduce="(val) => val.value"
          >
            <template #option="data">
              {{ $t(`invoice.invoiceStatus.${data.label}`) }}
            </template>

            <template #selected-option="data">
              {{ $t(`invoice.invoiceStatus.${data.label}`) }}
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR Adjust type -->
      <b-col
        v-if="isAdjust || invoiceData?.referenceAdjustedInvoice"
        cols="12"
        class="px-25"
        md="2"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.create.detail.adjustmentType')"
          rules="required"
          vid="adjustmentType"
        >
          <b-form-group
            label-for="adjustmentType"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.create.detail.adjustmentType') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="adjustmentType"
              :value="invoiceData.adjustmentType"
              :name="$t('invoice.create.detail.adjustmentType')"
              :options="ADJUSTMENT_TYPE_OPTIONS"
              label="label"
              style="font-size: 1rem"
              :disabled="!isEditing"
              :clearable="false"
              :reduce="val => val.label"
              :placeholder="$t('invoice.create.detail.phAdjustmentType')"
              @input="(val) => $emit('update:invoiceData', { ...invoiceData, adjustmentType: val })"
            >
              <template #option="data">
                <span>
                  {{ $t(`invoice.ADJUSTMENT_TYPE.${data.label}`) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(`invoice.ADJUSTMENT_TYPE.${data.label}`) }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="adjustmentType"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Gắn nhãn -->
      <b-col
        cols="12"
        class="px-25"
        md="3"
      >
        <b-form-group :label="$t('invoice.create.info.assignTag')">
          <v-select
            :value="invoiceData.tags"
            class="w-100"
            style="font-size: 1rem"
            :options="tagOptions"
            taggable
            multiple
            push-tags
            label="label"
            clearable
            :disabled="!isEditing"
            :placeholder="$t('invoice.create.info.phAssignTag')"
            :reduce="(val) => val"
            :loading="loadingTags"
            @open="onOpenTagHandle"
            @input="(val) => $emit('update:invoiceData', { ...invoiceData, tags: val })"
          >
            <template #option="data">
              {{ data.label }}
            </template>

            <template #selected-option="data">
              {{ data.label }}
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- !SECTION -->

    <!-- SECTION row 2: thông tin bên mua -->
    <div class="px-75 mt-50">
      <h5
        class="fw-700 mb-0"
        style="margin-left: -0.75rem"
      >
        {{ $t('invoice.create.info.buyerInfo') }}:
      </h5>
      <b-row class="px-25 py-50 border-secondary rounded-lg bg-gray">
        <!-- ANCHOR Đại lý mua -->
        <b-col
          cols="12"
          class="px-25"
          md="2"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.agency')"
            rules="required"
            vid="agency"
          >
            <b-form-group
              label-for="agency"
              :class="{ 'is-invalid': errors[0] }"
            >
              <template #label>
                {{ $t('invoice.create.info.agency') }}
                <span class="text-danger">(*)</span>
              </template>
              <v-select
                id="agency"
                :value="invoiceData?.agency"
                style="font-size: 1rem;"
                :name="$t('invoice.create.info.agency')"
                :options="agencyOptions"
                :reduce="val => val"
                class="w-100 bg-white"
                append-to-body
                :calculate-position="withPopper"
                label="agencyCode"
                filterable
                :disabled="!isEditing"
                :clearable="true"
                :placeholder="$t('invoice.create.info.agency')"
                :loading="loadingAgency"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, agency: val, buyerEmail: val?.emailAddress }) "
                @open="onOpenAgencySelect"
              >
                <template #option="data">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="font-weight-bolder">{{ data?.agencyCode }}</span> <small>({{ data.agencyName }})</small>
                  </span>
                </template>

                <template #selected-option="data">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="font-weight-bolder">{{ data?.agencyCode }}</span>
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="agency"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <!-- ANCHOR Loại khách hàng -->
        <!-- <b-col
          cols="12"
          class="px-25"
          md="2"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerType')"
            rules=""
            vid="buyerType"
          >
            <b-form-group
              label-for="buyerType"
              :class="{ 'is-invalid': errors[0] }"
            >
              <template #label>
                {{ $t('invoice.create.info.buyerType') }}
              </template>
              <v-select
                id="buyerType"
                :value="invoiceData.customerType"
                class="w-100"
                :options="CUSTOMER_TYPE_OPTIONS"
                :clearable="false"
                style="font-size: 1rem"
                :disabled="!isEditing"
                :placeholder="$t('invoice.create.info.phBuyerType')"
                :reduce="(val) => val.value"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, customerType: val })"
              >
                <template #option="{ label }">
                  {{ $t(`invoice.create.info.${label}`) }}
                </template>
                <template #selected-option="{ label }">
                  {{ $t(`invoice.create.info.${label}`) }}
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerType"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col> -->

        <!-- ANCHOR tên người mua -->
        <b-col
          cols="12"
          class="px-25"
          md="2"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerName')"
            rules=""
          >
            <b-form-group label-for="buyerName">
              <template #label>
                {{ $t('invoice.create.info.buyerName') }}
                <!-- <span class="text-danger">(*)</span> -->
              </template>
              <vue-autosuggest
                id="buyerName"
                :value="invoiceData.buyerName"
                :name="$t('invoice.create.info.buyerName')"
                :suggestions="buyerOptions"
                :input-props="{
                  placeholder: `${$t('invoice.create.info.phBuyerName')}`,
                  state: `${errors[0] ? false : null}`,
                  disabled: !isEditing,
                  class: `form-control text-body px-25 ${errors[0] ? 'is-invalid' : ''}`
                }"
                :section-configs="sectionConfigs"
                :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion)"
                @input="onInputBuyerNameHandle"
              >
                <template slot-scope="{ suggestion }">
                  <div class="cursor-pointer">
                    <div class="fw-600">
                      {{ suggestion.item.lastName }} {{ suggestion.item.firstName }}
                    </div>
                    <small class="text-secondary">{{ suggestion.item?.company?.name }}</small>
                  </div>
                </template>
              </vue-autosuggest>
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerName"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <!-- ANCHOR tên công ty -->
        <b-col
          cols="12"
          class="px-25"
          md="2"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerCompany')"
            rules="required"
          >
            <b-form-group label-for="buyerCompany">
              <template #label>
                {{ $t('invoice.create.info.buyerCompany') }}
                <span class="text-danger">(*)</span>
              </template>
              <b-form-input
                id="buyerCompany"
                :value="invoiceData.buyerUnitName"
                :name="$t('invoice.create.info.buyerCompany')"
                :state="errors[0] ? false : null"
                :disabled="!isEditing"
                :placeholder="$t('invoice.create.info.phBuyerCompany')"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, buyerUnitName: val })"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerCompany"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <!-- ANCHOR MST -->
        <b-col
          cols="12"
          class="px-25"
          md="2"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerTaxCode')"
            rules="required"
          >
            <b-form-group label-for="buyerTaxCode">
              <template #label>
                {{ $t('invoice.create.info.buyerTaxCode') }}
                <span class="text-danger">(*)</span>
              </template>
              <b-row align-v="center">
                <b-col
                  cols="7"
                  class="pr-25"
                >
                  <b-form-input
                    id="buyerTaxCode"
                    :value="invoiceData.buyerTaxCode"
                    :placeholder="$t('invoice.create.info.buyerTaxCode')"
                    :formatter="onlyUpperCaseFormatter"
                    :disabled="!isEditing"
                    :state="errors[0] ? false : null"
                    @input="(val) => $emit('update:invoiceData', { ...invoiceData, buyerTaxCode: val })"
                  />
                </b-col>
                <b-col
                  cols="5"
                  class="pl-0"
                >
                  <!-- v-b-tooltip.hover="`${$t('invoice.create.info.findByTaxCode')}`" -->
                  <BButton
                    variant="primary"
                    size="sm"
                    class="p-50 text-nowrap w-100"
                    pill
                    :disabled="loadingGetCompanyByTaxCode || !invoiceData.buyerTaxCode || !isEditing"
                    @click="handleFindTaxCode(invoiceData.buyerTaxCode)"
                  >
                    <span v-if="loadingGetCompanyByTaxCode">
                      <BSpinner
                        variant="light"
                        small
                      />
                    </span>
                    <span
                      v-else
                      class="text-truncate w-100 d-block"
                    >
                      {{ $t('invoice.create.info.findByTaxCode') }}
                    </span>
                  </BButton>
                </b-col>
              </b-row>

              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerTaxCode"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <!-- ANCHOR địa chỉ + email-->
        <b-col
          cols="12"
          class="px-50 d-flex-between gap-2"
          md="3"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerAddress')"
            rules="required"
            class="flex-1"
          >
            <b-form-group label-for="buyerAddress">
              <template #label>
                {{ $t('invoice.create.info.buyerAddress') }}
                <span class="text-danger">(*)</span>
              </template>
              <b-form-input
                id="buyerAddress"
                :value="invoiceData.buyerAddress"
                :name="$t('invoice.create.info.buyerAddress')"
                :state="errors[0] ? false : null"
                :disabled="!isEditing"
                :placeholder="$t('invoice.create.info.phBuyerAddress')"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, buyerAddress: val })"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerAddress"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.info.buyerEmail')"
            rules="email"
            class="flex-1"
          >
            <b-form-group label-for="buyerEmail">
              <template #label>
                {{ $t('invoice.create.info.buyerEmail') }}
                <!-- <span class="text-danger">(*)</span> -->
              </template>
              <b-form-input
                id="buyerEmail"
                :value="invoiceData.buyerEmail"
                :name="$t('invoice.create.info.buyerEmail')"
                :state="errors[0] ? false : null"
                :disabled="!isEditing"
                :placeholder="$t('invoice.create.info.phBuyerEmail')"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, buyerEmail: val })"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="buyerEmail"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <!-- ANCHOR lưu thông tin kh -->
        <b-col
          cols="12"
          md="1"
          align-self="end"
          class="px-0"
        >
          <BButton
            variant="primary"
            size="sm"
            class="p-50 text-wrap mb-50"
            pill
            :disabled="!isEditing || !validSaveBuyerInfo"
            @click="handleSaveBuyerInfo"
          >
            {{ $t('invoice.create.info.saveBuyerInfo') }}
          </BButton>
        </b-col>
      </b-row>
    </div>
    <!-- !SECTION -->
  </BCard>
  <!-- !SECTION -->
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { computed, ref, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { VueAutosuggest } from 'vue-autosuggest'
import debounce from 'lodash/debounce'
import { createPopper } from '@popperjs/core'

import { PAYMENT_METHOD_LIST, CUSTOMER_TYPE_OPTIONS, INVOICE_STATUS } from '@/constants/invoice'
import store from '@/store'
import useInvoiceCreateHandle from '@/views/invoices/create/useInvoiceCreateHandle'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import { apiAgencies, apiCustomer } from '@/api'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect: () => import('vue-select'),
    flatPickr,
    BSpinner,
    ValidationProvider,
    BTooltip,
    VueAutosuggest,
  },
  props: {
    invoiceData: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
    isAdjust: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
    isInvoiceRequest: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const { toastError, toastSuccess } = useToast()
    const { getCompanyByTaxCode, loadingGetCompanyByTaxCode } = useInvoiceCreateHandle()
    const {
      loadingTags, getTags, getTemplateId, ADJUSTMENT_TYPE_OPTIONS, DEFAULT_VAT_RATE, TAX_RATE_TYPES,
    } = useInvoiceHandle()

    // data agency logged in
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    // tìm theo MST
    async function handleFindTaxCode(taxCode) {
      const res = await getCompanyByTaxCode(taxCode)
      if (res) {
        emit('update:invoiceData', {
          ...props.invoiceData,
          buyerUnitName: res.name,
          buyerPhoneNumber: res.phonenumber,
          buyerEmail: res.mail,
          buyerAddress: res.address,
        })
      }
    }

    // thông tin bên bán
    const companyOptions = computed(() => [
      props.isInvoiceRequest && isRoleF2.value
        ? agencyData.value?.parentAgency?.company || null // case F2 tạo F1 làm sau
        : agencyData.value?.company || null,
    ].filter(Boolean))
    let initCompany = false
    watch(companyOptions, val => {
      if (val.length && !initCompany) {
        initCompany = true
        if (val[0]) {
          emit('update:invoiceData', { ...props.invoiceData, company: val[0] })
        }
      } else if (agencyData.value && !val.length) {
        toastError('Công ty của đại lý chưa được cập nhật')
      }
    }, { deep: true, immediate: true })

    // mẫu hoá đơn (template)
    const invoiceTemplateOptions = ref([])
    async function getTemplateInvoice(id) {
      const res = await getTemplateId(id)
      return res?.data.filter(i => i?.active)
    }

    function handleSelectInvoiceTemplate(template) {
      if (!template) return
      // lấy các data theo template
      emit('update:invoiceData', {
        ...props.invoiceData,
        template,
        templateNumber: template?.templateNumber,
        symbol: template?.symbol,
        discountType: template?.discountType,
        taxRateType: template?.taxRateType,
        valueAddedTax: template?.taxRateType === TAX_RATE_TYPES.SINGLE ? (template?.valueAddedTax ?? DEFAULT_VAT_RATE) : undefined,
        invoiceType: template?.invoiceType,
      })
    }

    watch(
      () => props.invoiceData.company,
      async (newValue, oldValue) => {
        if (newValue?.id !== oldValue?.id) {
          // FIXME duplicate call === detail
          invoiceTemplateOptions.value = await getTemplateInvoice(props.invoiceData.company.id)

          // Nếu có 1 inv template => auto fill
          if (invoiceTemplateOptions.value && invoiceTemplateOptions.value?.length === 1) {
            handleSelectInvoiceTemplate(invoiceTemplateOptions.value[0])
          }
        }
      },
      { deep: true, immediate: true },
    )

    // tags
    const tagOptions = ref([])
    const tagOptionsDefault = ref([])
    async function onOpenTagHandle() {
      if (tagOptionsDefault.value.length) {
        tagOptions.value = cloneDeep(tagOptionsDefault.value)
        return
      }
      const res = await getTags()
      tagOptions.value = cloneDeep(res.tags)
      tagOptionsDefault.value = cloneDeep(res.tags)
    }

    // for save buyer info
    async function handleSaveBuyerInfo() {
      root.$bvModal.show('modal-api-loading')
      const splitBuyerName = props.invoiceData.buyerName.split(' ')
      const lastName = splitBuyerName[0]
      const firstName = splitBuyerName.splice(1).join(' ')
      const payload = {
        firstName,
        lastName,
        phoneNumber: '',
        gender: '',
        emailAddress: props.invoiceData?.buyerEmail ?? '',
        company: {
          name: props.invoiceData?.buyerUnitName ?? '',
          address: props.invoiceData?.buyerAddress ?? '',
          taxCode: props.invoiceData?.buyerTaxCode ?? '',
          email: props.invoiceData?.buyerEmail ?? '',
        },
      }
      try {
        const res = await apiCustomer.addCustomer(payload)
        if (res) { toastSuccess('invoice.msg.saveCustomerSuccess') }
      } catch (error) {
        toastError('invoice.msg.saveCustomerError')
      } finally {
        root.$bvModal.hide('modal-api-loading')
      }
    }

    const validSaveBuyerInfo = computed(() => props.invoiceData.buyerName
      && props.invoiceData.buyerEmail
      && props.invoiceData.buyerUnitName
      && props.invoiceData.buyerAddress
      && props.invoiceData.buyerTaxCode)

    //  for select buyer
    const buyerOptions = ref([])

    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          emit('update:invoiceData', {
            ...props.invoiceData,
            buyerName: `${selected.item?.lastName ?? ''} ${selected.item?.firstName ?? ''}`,
            buyerUnitName: selected.item?.company?.name ?? '',
            buyerTaxCode: selected.item?.company?.taxCode ?? '',
            buyerEmail: selected.item?.company?.email ?? '',
            buyerAddress: selected.item?.company?.address ?? '',
            buyerUnitCode: selected.item?.code ?? null,
          })
          buyerOptions.value = []
        },
      },
    })

    function getSuggestionValue(suggestion) {
      const { lastName, firstName } = suggestion.item
      return `${lastName ?? ''} ${firstName ?? ''}`.trim()
    }

    const debounceSearchCustomer = debounce(async searchText => {
      const res = await apiCustomer.fetchCustomers({ searchText })
      if (res) {
        buyerOptions.value = [{ data: res.data.items }]
      }
    }, 300)

    function onInputBuyerNameHandle(value) {
      const val = value?.trim()
      emit('update:invoiceData', { ...props.invoiceData, buyerName: val, buyerUnitCode: null })
      debounceSearchCustomer(val)
    }

    // agency
    const agencyOptions = ref([])

    const loadingAgency = ref(false)
    function onOpenAgencySelect() {
      if (!agencyData.value?.id || loadingAgency.value || agencyOptions.value.length) return
      loadingAgency.value = true
      apiAgencies
        .getChild(agencyData.value?.id, {
          isActive: true,
        })
        .then(response => {
          agencyOptions.value = [agencyData.value, ...response.data.items]
        })
        .finally(() => {
          loadingAgency.value = false
        })
    }

    return {
      onlyUpperCaseFormatter,
      PAYMENT_METHOD_LIST,
      Vietnamese,
      handleFindTaxCode,
      loadingGetCompanyByTaxCode,
      companyOptions,
      invoiceTemplateOptions,
      handleSelectInvoiceTemplate,
      tagOptions,
      CUSTOMER_TYPE_OPTIONS,
      INVOICE_STATUS,
      loadingTags,
      onOpenTagHandle,
      ADJUSTMENT_TYPE_OPTIONS,
      handleSaveBuyerInfo,
      validSaveBuyerInfo,
      buyerOptions,
      sectionConfigs,
      getSuggestionValue,
      onInputBuyerNameHandle,
      onOpenAgencySelect,
      agencyOptions,
      loadingAgency,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = '400px'
      dropdownList.style.width = dropdownWidth
      dropdownList.style.translate = `calc((${dropdownWidth} - ${width})/2)`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
